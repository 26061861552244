import { Component, Input, OnChanges } from "@angular/core";

import {
  Units,
  createUnitConverter,
  Orientation,
  toUnitString,
} from "../units";
import { Project } from "../../core/models/projectModel";
import { ModuleType } from "../moduleTypes";

const randOU = 0.15;
const hindernisse = 0.1;
const nachbar = 0.5;
const blitzschutz = 0.5;

@Component({
  styleUrls: ["./safety-distances-table.component.scss"],
  selector: "cm-safety-distances-table",
  templateUrl: "./safety-distances-table.component.html",
})
export class SafetyDistancesTableComponent implements OnChanges {
  @Input() sparrenBreite: number;
  @Input() sparrenAbstand: number;
  @Input() ersterSparrenAbstand?: number;
  @Input() deckBreite: number;
  @Input() deckLaenge: number;

  @Input() unit: Units;
  @Input() project: Project;
  @Input() module: ModuleType;

  randHorizontal: number;
  randVertikal: number;
  hindernisseHoehe: number;
  hindernisseBreite: number;
  nachbarhausFlatHoehe: number;
  nachbarhausFlatBreite: number;
  nachbarhausGabledBreite: number;
  nachbarhausGabledHoehe: number;
  blitzschutzHoehe: number;
  blitzschutzBreite: number;

  unitString: string;

  ngOnChanges() {
    this.unitString = toUnitString(this.unit);

    const toTargetUnit = createUnitConverter(
      this.unit,
      this.deckBreite,
      this.deckLaenge
    );

    this.randHorizontal = toTargetUnit(
      calculateRandLR(
        this.sparrenAbstand,
        this.sparrenBreite,
        this.ersterSparrenAbstand
      ),
      Orientation.Horizontal
    );
    this.randVertikal = toTargetUnit(randOU, Orientation.Vertical);
    this.hindernisseHoehe = toTargetUnit(hindernisse, Orientation.Vertical);
    this.hindernisseBreite = toTargetUnit(hindernisse, Orientation.Horizontal);
    this.blitzschutzHoehe = toTargetUnit(blitzschutz, Orientation.Vertical);
    this.blitzschutzBreite = toTargetUnit(blitzschutz, Orientation.Horizontal);
    this.nachbarhausFlatHoehe = toTargetUnit(
      getNachbarhausFlatRoofDistance(this.module, this.project),
      Orientation.Vertical
    );
    this.nachbarhausFlatBreite = toTargetUnit(
      getNachbarhausFlatRoofDistance(this.module, this.project),
      Orientation.Horizontal
    );
    this.nachbarhausGabledHoehe = toTargetUnit(
      getNachbarhausGabledRoofDistance(this.module, this.project),
      Orientation.Vertical
    );
    this.nachbarhausGabledBreite = toTargetUnit(
      getNachbarhausGabledRoofDistance(this.module, this.project),
      Orientation.Horizontal
    );
  }
}

function getNachbarhausFlatRoofDistance(module: ModuleType, project: Project) {
  return module.name === "New P2 Module" &&
    project.minDistanceNeighbourHouseFlatRoof !== null
    ? project.minDistanceNeighbourHouseFlatRoof / 100.0
    : nachbar;
}

function getNachbarhausGabledRoofDistance(
  module: ModuleType,
  project: Project
) {
  return module.name === "New P2 Module" &&
    project.minDistanceNeighbourHouseTiltedRoof !== null
    ? project.minDistanceNeighbourHouseTiltedRoof / 100.0
    : nachbar;
}

function calculateRandLR(
  sparrenAbstand: number,
  sparrenBreite: number,
  ersterSparrenAbstand?: number
) {
  const min = 0.1;
  const edgeWidth = 0.35;

  const w = sparrenBreite / 100.0;

  if (ersterSparrenAbstand) {
    const l = ersterSparrenAbstand / 100.0;
    return Math.max(l + 0.5 * w - edgeWidth, min);
  }

  const s = sparrenAbstand / 100.0;
  return Math.max(s + 1.5 * w - edgeWidth, min);
}
