export interface Project {
  id: string;
  description: string;
  orderNumber: string;
  customerName: string;
  opportunityId: string;
  createdAt: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  addressCenter: AddressCenter;
  initialImages: InitialImage[];
  roofs: ProjectRoof[];
  moduleName?: string;
  zipCode?: string;
  rafterWidth?: number;
  rafterDistance?: number;
  firstRafterDistance?: number;
  tileWidth?: number;
  tileHeight?: number;
  minDistanceNeighbourHouseFlatRoof: number;
  minDistanceNeighbourHouseTiltedRoof: number;
  sourceSystem: SourceSystem;
}

export interface ProjectRoof {
  locked: boolean;
  initialSideLength: number;
  groundImageScene: any;
  satelliteVizScene: any;
  satelliteGrid: any;

  panelType: string;
  shareableLink: string;

  sideLength: number;
  imageSize: ImageSize;
  userImageAngle: number;
  azimuth: number;
  panelsOrientation: string;
  numPlacedPanels: number;
  roofPitch: number;
  userImageUrl: string;
  groundGrid: GroundGrid;
  areas: Area[][];
  selectedAreas: Area[];
  selectedAreasCorners: Corner[];
  initialAreasCorners: Corner[];
  groundImageAreas: Area[][];
  userImageCorners: Corner[];
  initialGroundCorners: Corner[];
  panelWidthRatio: number;
  panelHeightRatio: number;
  plannedImageUrl: string;
  horizontalTileCount?: number;
  verticalTileCount?: number;
}

export interface AddressCenter {
  center: Center;
  config: Config;
}

export interface Center {
  latitude: number;
  longitude: number;
  address: string;
}

export interface Config {
  zoom: number;
  scale: number;
  size: string;
}

export interface GroundGrid {
  placed: number[][][];
  not_placed: number[][][];
}

export interface ImageSize {
  heigth: number;
  width: number;
}

export interface InitialImage {
  id: string;
  name: string;
  category: string;
  url: string;
}

export interface Area {
  type: string;
  parent: number;
  selected: boolean;
  modified: boolean;
  instance: number;
  index: number;
  points: number[][];
}

export interface Corner {
  x: number;
  y: number;
  name: number;
}

export enum SourceSystem {
  SalesOs = "SalesOs",
  Salesforce = "Salesforce",
}
