import { PlanningDataService } from "./../../core/services/planning-data.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  Component,
  NgZone,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _filter from "lodash/filter";
import { Project, SourceSystem } from "../../core/models/projectModel";
import { PanelPlacingCanvasComponent } from "./panel-placing-canvas/panel-placing-canvas.component";
import { ModalService, IModalContent } from "../../core/modal/modal.service";
import * as uuid from "uuid";
import * as dayjs from "dayjs";

@Component({
  selector: "cm-panel-placing",
  styleUrls: ["./panel-placing.component.css"],
  templateUrl: "./panel-placing.component.html",
})
export class PanelPlacingComponent implements OnInit {
  project: Project;
  uploadPlannedFilesToSalesforce: boolean = true;
  uploadUnplannedFilesToSalesforce: boolean = true;
  mode: string;
  @ViewChildren("panelPlacingCanvasComponent")
  canvases: QueryList<PanelPlacingCanvasComponent>;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected planningDataService: PlanningDataService,
    protected ngZone: NgZone,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.project = data.project;
      this.uploadPlannedFilesToSalesforce =
        this.uploadPlannedFilesToSalesforce &&
        this.project.sourceSystem === SourceSystem.Salesforce;
      this.uploadUnplannedFilesToSalesforce =
        this.uploadUnplannedFilesToSalesforce &&
        this.project.sourceSystem === SourceSystem.Salesforce;
    });
    this.route.queryParamMap.subscribe((params) => {
      this.mode = params.get("mode");
      if (this.mode === "edit") {
        this.uploadUnplannedFilesToSalesforce =
          false && this.project.sourceSystem === SourceSystem.Salesforce;
      } else {
        this.uploadUnplannedFilesToSalesforce =
          true && this.project.sourceSystem === SourceSystem.Salesforce;
      }
    });
  }

  ngOnDestroy() {}

  goToProjects() {
    this.router.navigate(["/projects", this.project.orderNumber]);
  }

  goBack() {
    const orderNumber = this.route.snapshot.paramMap.get("orderNumber");
    const projectId = this.route.snapshot.paramMap.get("projectId");
    this.router.navigate(
      [`/projects/${orderNumber}/${projectId}/image-upload`],
      { queryParamsHandling: "merge" }
    );
  }

  async onExportAndSaveNewVersion($event) {
    const newId = uuid.v4();
    this.project.id = newId;
    await this.exportAndSave($event);
    this.router.navigate(
      [
        `/projects/${this.project.orderNumber}/${this.project.id}/panel-placing`,
      ],
      { queryParams: { mode: "create" } }
    );
  }

  async exportAndSave($event) {
    try {
      const canvases = this.canvases.toArray();
      for (let i = 0; i < canvases.length; i++) {
        const canvas = canvases[i];
        canvas.acceptChanges();

        // If both upload file checkboxes are false, then save images locally.
        if (
          !this.uploadPlannedFilesToSalesforce &&
          !this.uploadUnplannedFilesToSalesforce
        ) {
          canvas.saveImage($event);
        }
      }
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      const url =
        baseUrl +
        this.router.createUrlTree(
          [
            `/projects/${this.project.orderNumber}/${this.project.id}/panel-placing`,
          ],
          { queryParams: { mode: "edit" } }
        );

      let message = `
        <p>The following link has been generated:</p>
        ${url}
      `;

      if (
        this.uploadPlannedFilesToSalesforce ||
        this.uploadUnplannedFilesToSalesforce
      ) {
        message += await this.exportImagesToSalesforce(
          $event,
          this.uploadPlannedFilesToSalesforce,
          this.uploadUnplannedFilesToSalesforce
        );
      }

      await this.planningDataService.persist(this.project);

      try {
        (navigator as any).clipboard.writeText(url).then((success) => {});
      } catch (e) {}

      const modalContent: IModalContent = {
        header: "Project finished",
        body: message,
        cancelButtonText: "Cancel",
        OKButtonText: "OK",
        cancelButtonVisible: false,
      };
      return this.modalService.show(modalContent).then((success) => {});
    } catch (e) {
      alert("An error has occurred: " + e);
    }
  }

  async onExportAndSave($event) {
    return this.exportAndSave($event);
  }

  async exportImagesToSalesforce(
    $event,
    uploadPlanned: boolean,
    uploadUnplanned: boolean
  ) {
    var message = `
    <p>The following images have been saved to Salesforce:</p>
  `;

    try {
      const canvases = this.canvases.toArray();
      const dateString = dayjs().format("YYYYMMDDHHmm");

      for (let i = 0; i < canvases.length; i++) {
        const canvas = canvases[i];
        const planningImage = await canvas.saveImageAsBlob($event);
        const panelOrientation =
          canvas.roof.panelsOrientation === "horizontal" ? "H" : "V";
        const fileNameSuffix = `${i + 1}_${panelOrientation}_${
          canvas.roof.numPlacedPanels
        }_${dateString}.png`;

        if (uploadPlanned) {
          const plannedFileName = `Planned${fileNameSuffix}`;
          const category = `Sc2CustomerImageRoofPlanned${canvas.roofIndex + 1}`;
          const plannedRoofFile =
            await this.planningDataService.postProjectPlannedImage(
              this.project.orderNumber,
              this.project.id,
              canvas.roofIndex,
              planningImage,
              plannedFileName,
              category
            );
          message += `<a href="${plannedRoofFile.salesforceUrl}" target="_blank">${plannedRoofFile.fileName}</a><br>`;
          this.project.roofs[canvas.roofIndex].plannedImageUrl =
            plannedRoofFile.storageUrl;
        }

        if (uploadUnplanned) {
          const unplannedFileName = `Roof${fileNameSuffix}`;
          const unplannedRoofBlob = await fetch(canvas.roof.userImageUrl).then(
            (res) => res.blob()
          );
          await this.planningDataService.postPlanningImageToSalesforceWithCategory(
            this.project.orderNumber,
            unplannedRoofBlob,
            unplannedFileName,
            `Sc2CustomerImageRoof${canvas.roofIndex + 1}`
          );
        }
      }

      return message;
    } catch (e) {
      alert("An error has occurred: " + e);
    }
  }

  openPanelCalculation() {
    const pathname = window.location.pathname;
    const p = pathname.lastIndexOf("/");
    const uri = pathname.substring(0, p + 1) + "panel-calculation";
    const width = 980;
    const height = 785;
    window.open(
      uri,
      "calculation-popup",
      `popup,innerWidth=${width},innerHeight=${height}`
    );
  }
}
