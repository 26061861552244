import { PlanningDataService } from "./../../core/services/planning-data.service";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import _forEach from "lodash/forEach";
import { AgmMap, MapsAPILoader } from "@agm/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { environment as env } from "../../../environments/environment";
import {
  InitialImage,
  Project,
  SourceSystem,
} from "../../core/models/projectModel";
declare var Microsoft: any;

@Component({
  styleUrls: ["./map-selection.component.css"],
  selector: "cm-map-selection",
  templateUrl: "./map-selection.component.html",
})
export class MapSelectionComponent implements OnInit {
  config = {};
  mapConfig: any;
  isVisible = true;

  public latitude: number;
  public longitude: number;
  public searchControl: FormControl;
  public zoom: number;
  public project: Project;

  center: any;
  address: string;
  roofPitch: any = 40;
  bingMapsObj: any;
  results: any[] = [];
  queryField: FormControl = new FormControl();
  dopMapsUrl: SafeResourceUrl;
  latestLat: number;
  latestLng: number;
  images: InitialImage[] = [];
  linkToSourceSystem: string;
  canvasWidth: number;
  canvasHeight: number;

  @ViewChild("search") public searchElementRef: ElementRef;
  @ViewChild("googleMapsElement") public googleMapsElementRef: ElementRef;
  @ViewChild(AgmMap) mapElement: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private planningDataService: PlanningDataService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private loadingBar: LoadingBarService,
    private domSanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.route.data.subscribe((data) => {
      this.project = data.project;
      this.linkToSourceSystem =
        this.project.sourceSystem === SourceSystem.SalesOs
          ? `${env.systemDesignUrl}/${this.project.opportunityId}`
          : `${env.salesforceUrl}/${this.project.opportunityId}`;

      this.images = this.project.initialImages;

      //set google maps defaults
      this.zoom = 20;
      this.latitude = this.project.addressCenter.center.latitude;
      this.longitude = this.project.addressCenter.center.longitude;
      this.dopMapsUrl = this.getDopMapSrc();

      this.center = {
        latitude: this.latitude,
        longitude: this.longitude,
        address: `${this.latitude}, ${this.longitude}`,
      };

      let size = env.map.DEFAULT_MAP_IMAGE_SIZE;

      if (
        this.project.addressCenter &&
        this.project.addressCenter.config &&
        this.project.addressCenter.config.size
      ) {
        size = this.project.addressCenter.config.size;
      }

      [this.canvasHeight, this.canvasWidth] = size
        .split(/x/g)
        .map((s) => parseInt(s, 10));

      //create search FormControl
      this.searchControl = new FormControl();
      this.searchControl.setValue(`${this.latitude}, ${this.longitude}`);
      this.initMap();
    });
  }

  async initMap() {
    //load Places Autocomplete
    await this.mapsAPILoader.load();
  }

  getDopMapSrc() {
    if (this.latitude && this.longitude) {
      const timestamp = (Date.now() / 1000) | 0;
      const url = `https://sg.geodatenzentrum.de/dop_viewer/viewer.html?dummy=${timestamp}#lat=${this.latitude}&lon=${this.longitude}&zoom=16.986666666666665&webatlasde=true&dop40=false&layer_4=false&layer_3=true&layer_2=true&layer_1=false`;
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
    return undefined;
  }

  async performBingGeocoding(addressText) {
    try {
      const result = await this.planningDataService.geocode(addressText);
      const lat = result.location.lat();
      const lng = result.location.lng();

      //set latitude, longitude and zoom
      this.latitude = lat;
      this.longitude = lng;
      this.address = addressText;
      this.center = {
        latitude: lat,
        longitude: lng,
        address: addressText,
      };
      this.zoom = 20;
    } catch (e) {
      console.error(e);
      alert("Geocoding failed. Could not find address!");
    }
  }

  searchPlace() {
    this.project.roofs.splice(1, this.project.roofs.length - 1);
    const addressText = this.searchElementRef.nativeElement.value;
    this.performBingGeocoding(addressText);

    // Wait for mapCenter to update, then update the lat/lng and dopMap.
    setTimeout(() => {
      this.latitude = this.latestLat;
      this.longitude = this.latestLng;
      this.dopMapsUrl = this.getDopMapSrc();
    }, 300);
  }

  onEnterPressedOnSearch($event) {
    this.searchPlace();
  }

  autoSuggestPlaces(query) {
    var places = ["Seattle", "Madagascar"];
    return places;
  }

  initBingAutosuggest() {
    Microsoft.Maps.loadModule("Microsoft.Maps.AutoSuggest", {
      callback: onLoad,
      errorCallback: onError,
    });

    var that = this;

    function onLoad() {
      var options = { maxResults: 5 };
      var manager = new Microsoft.Maps.AutosuggestManager(options);
      manager.attachAutosuggest(
        "#searchBox",
        "#searchBoxContainer",
        onSuggestionSelection
      );
    }

    function onError(message) {
      document.getElementById("printoutPanel").innerHTML = message;
    }

    function onSuggestionSelection(suggestionResult) {
      setTimeout(() => {
        that.latitude = suggestionResult.location.latitude;
        that.longitude = suggestionResult.location.longitude;
        that.zoom = 20;

        that.mapElement.triggerResize(true);
      }, 1);
    }
  }

  zoomChange($event) {
    //this.mapElement._mapsWrapper._map.__zone_symbol__value.zoom = 20;
  }

  mapReady(map) {
    map.addListener("dragend", () => {
      this.latitude = this.latestLat;
      this.longitude = this.latestLng;
      this.center = {
        latitude: this.latestLat,
        longitude: this.latestLng,
        address: this.address,
      };
      this.searchControl.setValue(
        `${this.center.latitude}, ${this.center.longitude}`
      );
      this.dopMapsUrl = this.getDopMapSrc();
    });
  }

  mapCenterChange($event) {
    this.latestLat = $event.lat;
    this.latestLng = $event.lng;
  }

  ngOnDestroy() {}

  onLoadConfig() {
    this.router.navigate(["load"]);
  }

  goToProjects() {
    this.router.navigate(["/projects", this.project.orderNumber]);
  }

  async onGoToNext() {
    try {
      var actualZoomLevel =
        this.mapElement._mapsWrapper._map.__zone_symbol__value.zoom;
      this.mapConfig = {
        zoom: actualZoomLevel,
      };

      this.project.addressCenter.center = this.center;
      this.roofPitch = 40;
      this.project.roofs[0].roofPitch = 40;
      const size = this.project.addressCenter.config.size;

      // Adds an image with the google maps satellite view to the image list.
      let gmapImage = this.project.initialImages.find(
        (i) => i.category === "google-maps-image"
      );
      const url = `https://maps.googleapis.com/maps/api/staticmap?center=${this.center.latitude},${this.center.longitude}&zoom=20&size=${size}&maptype=satellite&key=${env.map.MAPS_API_KEY}`;
      if (!gmapImage) {
        gmapImage = {
          id: "google-maps-image",
          category: "google-maps-image",
          name: "google-maps-image.png",
          url,
        };
        this.project.initialImages.push(gmapImage);
      }
      gmapImage.url = url;

      this.planningDataService.persist(this.project);

      const orderNumber = this.route.snapshot.paramMap.get("orderNumber");
      const projectId = this.route.snapshot.paramMap.get("projectId");

      // Navigate to the next step
      this.router.navigate(
        [`/projects/${orderNumber}/${projectId}/area-corners`],
        { queryParamsHandling: "merge" }
      );
    } catch (e) {
      alert(`An error has occurred: ${e.message}`);
    }
  }

  generateAreas(data) {
    var pointsCounter = 0;
    var instancesCounter = 0;

    var instancesArray = [];

    _forEach(data, (instance, i) => {
      i = i + 1; // The indexes start from 1 in the backend
      instancesCounter++;

      var types = {};
      var areasArray = [];
      _forEach(instance, (area, j) => {
        if (types[area.type]) {
          types[area.type]++;
        } else {
          types[area.type] = 1;
        }

        var points = [];
        _forEach(area.points, (point, index) => {
          // if (area.points.length > 5) {
          //     // if (index % EVERY_X_PREDICTION_POINTS === 0) {
          //     //     points.push(point);
          //     // }
          //     points.push(point);
          //} else {
          pointsCounter++;
          points.push(point);
          //}
        });

        areasArray.push({
          points: points,
          type: area.type,
          parent: area.parent,
          selected: false,
          modified: false,
          instance: i,
          index: j,
        });
      });
      instancesArray.push(areasArray);
    });
    return instancesArray;
  }
}
