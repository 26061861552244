export const moduleTypes = [
  {
    name: "JA Solar JAM54S30 410/MR",
    productSku: "EP_249",
    weight: 21.5,
    length: 1722,
    width: 1134,
    thickness: 30,
    mounting: 1.255,
  },
  {
    name: "JA Solar JAM60S20 385/MR",
    productSku: null,
    weight: 0,
    length: 1777,
    width: 1052,
    thickness: 0,
    mounting: 1.1395,
  },
  {
    name: "GCL-M10/54H 410",
    productSku: null,
    weight: 0,
    length: 1722,
    width: 1134,
    thickness: 30,
    mounting: 1.255,
  },
  {
    name: "Longi LR5-54HiH 410M",
    productSku: null,
    weight: 0,
    length: 1722,
    width: 1134,
    thickness: 30,
    mounting: 1.255,
  },
  {
    name: "New P2 Module",
    productSku: null,
    weight: 0,
    length: 1762,
    width: 1134,
    thickness: 30,
    mounting: 1.255,
  },
];

export type ModuleType = (typeof moduleTypes)[number];
